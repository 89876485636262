.Checkbox {
    margin: 0px 0 10px;
    display: flex;
    // align-items: center;
    // justify-content: flex-start;
    justify-content: space-between;
}

.CheckboxGroup {
    margin: 0px 0 10px;
    display: flex;
    // align-items: center;
    justify-content: flex-start;
    // justify-content: space-between;
}

.checkboxred {
    color: red;
    margin-bottom: 10px;
    width: 100%;
    // text-align: right;
    font-family: Regular;
}

.RadioGroup {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.followbtn {
    margin-bottom: 20px;
}

.addbtn {
    width: 90px;
    height: 34px;
    background: #3FB97C;
    opacity: 1;
    border-radius: 2px;
    font-size: 16px;
    font-family: PingFang SC;
    font-weight: 400;
    line-height: 22px;
    color: #FFFFFF;
    opacity: 1;
    border: 0;

    &:hover {
        opacity: 0.8;
    }
}

.delatebtn {
    width: 90px;
    height: 34px;
    background: #9AA7BE;
    opacity: 1;
    border-radius: 2px;
    font-size: 16px;
    font-family: PingFang SC;
    font-weight: 400;
    line-height: 22px;
    color: #FFFFFF;
    border: 0;
    margin-left: 22px;

    &:hover {
        background: #65789B;
    }
}

.empty {
    height: calc(100vh - 300px);
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.emptytext {
    font-size: 16px;
    font-family: PingFang SC;
    font-weight: 400;
    line-height: 22px;
    color: rgba(0, 0, 0, 0.85);
    opacity: 1;
    margin: 10px 0 30px;
}

//按钮
.exposebtn {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 74px;
}

.formbtnleft {
    width: 120px;
    height: 40px;
    // background-color: #3F59AE;
    background-color: #FFFFFFFF;
    color: #3F59AE;
    font-size: 16px;
    line-height: 40px;
    text-align: center;
    font-family: PingFang SC;

    // border: 0;
    border: 1px solid #3F59AE;
    border-radius: 2px;
    margin: 0 28px;

    &:hover {
        background-color: #fff;
        color: #374C92;
        border: 1px solid #374C92;
        cursor: pointer;
    }

}

.formbtnright {
    width: 120px;
    height: 40px;
    background-color: #3F59AE;
    // background-color: #FFFFFFFF;
    color: #fff;
    font-size: 16px;
    line-height: 40px;
    text-align: center;
    font-family: PingFang SC;

    // border: 0;
    border: 1px solid #3F59AE;
    border-radius: 2px;
    margin: 0 28px;

    &:hover {
        background-color: #374C92;
        border: 1px solid #374C92;
        color: #fff;
        cursor: pointer;
    }
}

.followdanger {
    width: 90px;
    height: 34px;
    background: #ff4d4f;
    opacity: 1;
    border-radius: 2px;
    font-size: 16px;
    font-family: PingFang SC;
    font-weight: 400;
    line-height: 22px;
    color: #FFFFFF;
    border: 0;
    margin-left: 22px;

    &:hover {
        background-color: red;
        border: 1px solid red;
        color: #fff;
        cursor: pointer;
    }
}

.addbtnUpload {
    height: 34px;
    background: #3F53B9;
    opacity: 1;
    border-radius: 2px;
    font-size: 16px;
    font-family: PingFang SC;
    font-weight: 400;
    line-height: 22px;
    color: #FFFFFF;
    opacity: 1;
    border: 0;
    margin-left: 22px;

    &:hover {
        background-color: #3F53B9;
        opacity: 0.8;
        color: #fff;
        cursor: pointer;
    }

    &:active {
        background-color: #3F53B9;
        opacity: 0.8;
        color: #fff;
        cursor: pointer;
    }

    &:focus {
        background-color: #3F53B9;
        opacity: 0.8;
        color: #fff;
        cursor: pointer;
    }

    ::selection {
        background-color: #3F53B9 !important;
        --antd-wave-shadow-color: #3F53B9;
    }
}

.dowmload_addbtn {
    width: 90px;
    height: 34px;
    background-color: #fff;
    border: 1px solid #2B5AC8;
    opacity: 1;
    border-radius: 2px;
    font-size: 16px;
    font-family: PingFang SC;
    font-weight: 400;
    line-height: 22px;
    color: #FFFFFF;
    opacity: 1;

    .download {

        font-size: 16px;
        font-family: PingFang SC;
        font-weight: 400;
        line-height: 22px;
        color: #2B5AC8;

        &:hover {



            color: #2B5AC8;
            cursor: pointer;
            opacity: 0.8;
        }
    }

    &:hover {



        opacity: 0.8;

    }
}

.inputRef_save1 {
    width: 90% !important;
    box-sizing: border-box !important;
}

.inputRef_save {
    min-width: 200px;
    height: 32px;
    box-sizing: border-box !important;
}

.inputRef_save222 {}

.editable-cell-value-wrap {
    min-width: 203px;

    height: 32px;
}

.addUpload_box {}

.empty .addUpload_box .download_btn {
    display: inline-block;
    width: 90px;
    height: 34px;
    background: #FFFFFF;
    border-radius: 2px;
    border: 1px solid #105EBF;
    color: #105EBF;
    cursor: pointer;

    .download {

        font-size: 16px;
        font-family: PingFang SC;
        font-weight: 400;
        line-height: 22px;
        color: #105EBF;

        &:hover {



            color: #105EBF;
            cursor: pointer;
            opacity: 0.8;
        }
    }

    &:hover {



        opacity: 0.8;

    }
}


.add_Upload {
    display: inline-block;
    width: 137px;
    height: 200px;
    position: relative;
}

.ant-upload-list {
    position: absolute;
    top: 30px;
    left: -15px;
    width: 200px;
}

.ant-btn-default:not(:disabled):not(.ant-btn-disabled):hover {

    background: #3F53B9;
    color: #FFFFFF;
    border-color: #3F53B9;
    opacity: 0.8;
}

.antd_table_thead .ant-table-thead tr th {
    font-family: PingFang SC;
    font-weight: 400;
    font-size: 16px;
    color: rgba(0, 0, 0, 0.85);
}
.antd_table_thead .ant-table-thead tr th.follow-class {
    color: #E60505;
}
.ant-radio-wrapper{
    font-family: Regular;

    font-size: 16px;

}
