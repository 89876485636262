.flex {
    display: flex;
    flex-wrap: wrap;
}

.legendHei {
    height: 80px !important;
    width: 863px;
    margin: auto;
}
.page{
    display: flex ;
    justify-content: flex-end ;
}