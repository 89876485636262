.login-container {
    width: 100%;
    height: 100%;
    box-shadow: 0px 25px 60px rgba(9, 44, 99, 0.24);
    border-radius: 0px;
    // -webkit-app-region: drag;
    overflow: hidden;
    -webkit-user-select: none;
    user-select: none;
    display: -webkit-flex;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #E4E7EC;
}

.login-header {
    display: -webkit-flex;
    display: flex;
    flex-direction: column;
}

.login-header-button-container {
    padding: 14px 15px 6px 0px;
    box-sizing: border-box;
    display: -webkit-flex;
    display: flex;
    flex-direction: row;

    img {
        width: 16px;
        height: 16px;
        margin: 7px 7px 0 7px;
    }
}

.login-header-logo-container {
    display: flex;

    img {
        width: 140px;
        height: 140px;
    }
}

.login-header-title {
    text-align: center;
    font-family: "Bold", sans-serif;
    font-size: 26px;
    color: #29408A;
    line-height: 37px;
    height: 37px;
    margin-top: -14px;
    margin-bottom: 26px;
}

.login-form {
    display: -webkit-flex;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

}

.input-box {
    display: -webkit-flex;
    display: flex;
    flex-direction: column;
}

.input-container {
    display: -webkit-flex;
    display: flex;
    flex-direction: row;

}

.login-space {
    flex: 1 1 0;
}

.input-container-box {
    display: -webkit-flex;
    display: flex;
    flex-direction: row;
    border: 1px solid #D9D9D9;
    border-radius: 2px;
    width: 400px;
    height: 48px;
    box-sizing: border-box;
    margin-bottom: 28px;

    img {
        height: 22px;
        width: 22px;
        margin-top: 13px;
        margin-left: 17px;
        margin-right: 10px;
    }

    input {
        font-family: "Regular", sans-serif;
        font-size: 18px;
        width: 330px;
        height: 25px;
        line-height: 25px;
        vertical-align: top;
        margin-top: 12px;
        border: none;
        -webkit-app-region: no-drag;
        padding: 0px;
        outline: none;

        &:focus {
            border-color: #ffffff;
            box-shadow: none;
            outline: none;
        }

        &::placeholder {
            color: #999999;
        }
    }
}

.login-option {
    display: -webkit-flex;
    display: flex;
    flex-direction: row;
}

.login-option-container {
    width: 400px;
    display: flex;
    display: -webkit-flex;
    flex-direction: row;

    span {
        font-size: 16px;
        height: 22px;
        line-height: 22px;
        color: rgba(0, 0, 0, 0.65);
        font-family: "Regular", sans-serif;
    }

    input {
        margin: 3px 0;
        width: 16px;
        height: 16px;
        vertical-align: top;
        border: 1px solid #D9D9D9;
        cursor: pointer;
        -webkit-app-region: no-drag;
    }
}


.login-btn {
    width: 402px;
    height: 50px;
    background: #3F59AE;
    opacity: 1;
    border-radius: 2px;
    cursor: pointer;
    border: none;
    align-self: center;
    font-size: 18px;
    font-family: "PingFangRegular", sans-serif;
    text-align: center;
    color: #ffffff;
    font-weight: 400;
    // line-height: 32px;
    margin-top: 10px;
    margin-bottom: 23px;
    outline: none;
    -webkit-app-region: no-drag;
    box-shadow: 0px 8px 18px rgba(4, 35, 136, 0.3);

    &:hover {
        background: #4967C6;
        box-shadow: 0px 8px 18px rgba(4, 35, 136, 0.3);
    }

    &:active {
        background: #374C92;
        box-shadow: 0px 8px 18px rgba(4, 35, 136, 0.3);
    }
}

.login-others {
    display: -webkit-flex;
    display: flex;
    flex-direction: row;
}

.login-others-container {
    display: -webkit-flex;
    display: flex;
    flex-direction: row;
    width: 400px;
    justify-content: center;

    span {
        margin-top: 4px;
        margin-bottom: 4px;
        font-size: 16px;
        text-align: center;
        line-height: 22px;
        color: rgba(0, 0, 0, 0.65);
    }

    img {
        height: 30px;
        -webkit-app-region: no-drag;
        cursor: pointer;
    }
}


#wxlogin {
    height: 216px;
    width: 216px;
    padding: 10px;
    border: 1px solid #D9D9D9;

    img {
        width: 200px;
    }
}

.login-container_fl {
    width: 55%;
    background: url(../images/login/login_fl_back.png) no-repeat;
    height: 655px;

    background-size: 100% 100%;
    padding: 34px;
    max-height: 655px;
}

.login-container_fr {
    width: 45%;
    min-width: 416px;
    height: 655px;
    max-height: 774px;

    background-color: #ffffff;

}

.login-container_fl_box {
    display: -webkit-flex;
    display: flex;
    align-items: center;
    justify-content: center;
}

.login-container_fl_box_center h3 {
    height: 53px;
    font-size: 38px;
    font-family: PingFang SC;
    font-weight: bold;
    line-height: 53px;
    color: #FFFFFF;
    opacity: 1;
    margin-bottom: 30px;
}

.login-container_fl_top_img {
    width: 99px;
    height: 84px;

    img {
        width: 100%;
    }
}

.login-container_fl_center_img {


    width: 354px;
    height: 400px;

    img {
        width: 100%;
    }
}

.login-header_fr-title {
    width: 402px;
    height: 33px;
    font-size: 24px;
    font-family: PingFang SC;
    font-weight: bold;
    line-height: 33px;
    color: #323232;
    margin-bottom: 37px;
    margin-top: 100px;
}

.login-bottom-title {

    height: 28px;
    font-size: 20px;
    font-family: PingFang SC;
    font-weight: 400;
    line-height: 28px;
    color: #374682;
    margin-top: 90px;

}

.login-container-box {
    min-width: 1408px;
    // width: 80%;
    // height: 85%;
}
