.content{
    padding-top: 50px;
}
.analysis{
    height: 173px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    border-radius: 2px;
    padding-left: 34px;
    margin-bottom: 54px;
}
.onecommon{
    border: 1px solid rgba(63, 89, 174, 0.45098039215686275);
}
.oneactive{
    background: rgba(53, 112, 199, 0.1);
    border: 1px solid #3570C7;  
}
.twoactive{
    background: rgba(216, 122, 86, 0.1);
    border: 1px solid #D87A56;
}
.twocommon{
    border: 1px solid rgba(238, 149, 115, 0.45098039215686275);
}
.analysis-left{
    height: 96px;
    margin-left: 24px;
    // height: 100%;
    display: flex;
    flex-direction: column;
    padding-top: 8px;

}
.left-title{
    font-size: 18px;
    font-family: PingFang SC;
    font-weight: bold;
    line-height: 25px;
    color: #0E1437;
    opacity: 1;
    margin-bottom: 12px;
}
.left-content{
    font-size: 16px;
    font-family: PingFang SC;
    font-weight: 400;
    line-height: 22px;
    color: #0E1437;
    opacity: 1;
}
// .formbtn{
//     width: 120px;
//     height: 40px;
//     // background-color: #3F59AE;
//     background-color: #FFFFFFFF;
//     color: #3F59AE;
//     font-size: 16px;
//     line-height: 40px;
//     text-align: center;
//     // border: 0;
//     border: 1px solid #3F59AE;
//     border-radius: 2px;
//     &:hover {
//         background-color: #3F59AE;
//         color: #fff;
//     }
// }
.exposebtn{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10px!important;
}
.formbtnleft{
    width: 120px;
    height: 40px;
    // background-color: #3F59AE;
    background-color: #FFFFFFFF;
    color: #3F59AE;
    font-size: 16px;
    line-height: 40px;
    text-align: center;
    font-family: PingFang SC;

    // border: 0;
    border: 1px solid #3F59AE;
    border-radius: 2px;
    margin: 0 28px;
    &:hover {
        background-color: #fff;
        color: #374C92;
        border: 1px solid #374C92;
        cursor:pointer;
    }

}
.formbtnright{
    width: 120px;
    height: 40px;
    background-color: #3F59AE;
    // background-color: #FFFFFFFF;
    color: #fff;
    font-size: 16px;
    line-height: 40px;
    text-align: center;
    // border: 0;
    font-family: PingFang SC;

    border: 1px solid #3F59AE;
    border-radius: 2px;
    margin: 0 28px;
    &:hover {
        background-color: #374C92;
        border: 1px solid #374C92;
        color: #fff;
        cursor:pointer;
    }
}
