.controlmodel{
    width: 100%;
    height: 0;
    padding-bottom:34.44%;
    background-image: url('../../assets/images/controlModel/modelbg.png');
    background-repeat: no-repeat;
    background-size: 95% 100%;
    background-color: #F5F5FA;
    background-position: center;
    position: relative;
}
.aljiantou{
    position: absolute;
    bottom: 9%;
    left: 9%;
    width: 86%;
    z-index: 1;
}
.waihuo{
    position: absolute;
    bottom: 5%;
    left: 24.4%;
    width: 35.9%;
    z-index: 1;
}
.fanghuo{
    position: absolute;
    bottom: 30%;
    right: 17%;
    width: 27%;
}
.wartertou{
    position: absolute;
    bottom: 27%;
    right: 15%;
    width: 29%;
}
.rightren{
    position: absolute;
    width: 7.6%;
    right: 26.5%;
    bottom: 30%;
}
.di{
    position: absolute;
    width: 24%;
    bottom: 24%;
    left: 24%;
}
.leftren{
    position: absolute;
    width: 7.6%;
    left: 32.4%;
    bottom: 30%;
    z-index: 1;
}
.re{
    position: absolute;
    width: 14.5%;
    left: 28.95%;
    bottom: 29.5%;
    z-index: 1;
}
.leftre{
    position: absolute;
    width: 14.5%;
    right: 23.1%;
    bottom: 29.5%;
    z-index: 1;
}
.tong{
    position: absolute;
    width: 19.7%;
    left: 13.6%;
    bottom: 11.6%;
    z-index: 1;
}
.sixjiantou{
    position: absolute;
    width: 16.2%;
    left: 28%;
    bottom: 32%;
    z-index: 1;
}

.rightfei{
    position: absolute;
    width: 3.1%;
    left: 34.6%;
    top: 14.9%;
    z-index: 2;
}
.rightqiguan{
    position: absolute;
    width: 2.6%;
    left: 34.75%;
    top: 16%;
    z-index: 2;
}
.rightup{
    position: absolute;
    width: 1.8%;
    left: 32.7%;
    top: 14%;
    z-index: 2;
}
.rightdown{
    position: absolute;
    width: 1.7%;
    left: 32.5%;
    top: 8.5%;
    z-index: 2;
}
.rightqi{
    position: absolute;
    width: 10%;
    left: 24.4%;
    top: 11%;
    z-index: 2;
}
.rightwu{
    position: absolute;
    width: 11%;
    left: 23.3%;
    top: 4.2%;
    z-index: 2;
}
.leftfei{
    position: absolute;
    width: 3.1%;
    right: 28.8%;
    top: 14.9%;
    z-index: 2;
}
.leftqiguan{
    position: absolute;
    width: 2.6%;
    right: 29.1%;
    top: 16%;
    z-index: 2;
}

.leftup{
    position: absolute;
    width: 1.8%;
    right: 32%;
    top: 14%;
    z-index: 2;
}

.leftdown{
    position: absolute;
    width: 1.7%;
    right: 31%;
    top: 8.5%;
    z-index: 2;
}

.leftqi{
    position: absolute;
    width: 10%;
    left: 24.4%;
    top: 11%;
    z-index: 2;
}
.leftwu{
    position: absolute;
    width: 11%;
    right: 32.2%;
    top: 4.2%;
    z-index: 2;
}

.waishui{
    position: absolute;
    width: 74%;
    left: 9.8%;
    bottom: 1.3%;
    z-index: 2;
}
.fangwater{
    position: absolute;
    width: 16%;
    right: 22.5%;
    bottom: 25%;
    z-index: 2;
}
