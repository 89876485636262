body {
  margin: 0;
  // font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  //   'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  //   sans-serif;
  // -webkit-font-smoothing: antialiased;
  // -moz-osx-font-smoothing: grayscale;
  // font-family: ;
  font-family: "微软雅黑"!important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} 



@font-face {
  font-family: "Regular";
  src: url(../src/assets/fonts/PingFangRegular.ttf) format("truetype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Bold";
  src: url(../src/assets/fonts/PingFangBold.ttf) format("truetype");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "Medium";
  src: url(../src/assets/fonts/PingFangMedium.ttf) format("truetype");
  font-weight: normal;
  font-style: normal;
}

#root{
  height: 100%;
  width: 100%;
}

html{
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
}

body {
  margin: 0;
  line-height: 1;
  padding: 0;
  width: 100%;
  height: 100%;
  line-height: 22px;
  font-size: 14px;
  overflow: hidden;
  overflow-y: auto;
  -webkit-text-size-adjust: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
body, h1, h2, h3, h4, h5, h6, hr, p, blockquote, dl, dt, dd, ul, ol, li, pre, form, fieldset, legend, button, input, textarea, th, td { 
  margin: 0; 
  padding: 0; 
  box-sizing: border-box;
} 
ol, ul, li {
  list-style: none;
}
blockquote, q {
  quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
address, caption, cite, code, dfn, em, strong, th, var, optgroup { 
  font-style: normal; 
  font-weight: normal;
}
fieldset, img { 
  border:0;
}
textarea { 
  resize: none !important;
}
.fr {
  float: right;
}

.fl {
  float: left;
}

.clearfix {
  zoom: 1;
}

.clearfix::after {
  content: ".";
  display: block;
  visibility: hidden;
  height: 0;
  clear: both;
}

.ant-dropdown-menu-submenu-title {
  font-family: Regular;

}

.ant-form-item-required,
.ant-form-item-label>label {
  font-family: Regular;

}

.ant-select-item-option-content {
  font-family: Regular;

}

.ant-select-selection-item,
.ant-select-item {
  font-family: Regular;

}

.ant-dropdown-menu-item {
  font-family: Regular;

}

.ant-table-cell {
  font-family: Regular;

}

.ant-btn {
  font-family: Regular;
}

.ant-picker-input>input,
.ant-input {
  font-family: Regular;

}
