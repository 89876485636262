// .ps-content{
//     padding-top: 50px;
// }
.setting{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.modeliconleft{
    margin-top: 10px;
    font-size: 16px;
    font-family: PingFang SC;
    font-weight: bold;
    line-height: 24px;
    color: rgba(0, 0, 0, 0.85);
    opacity: 1;
    position: relative;
    margin-left: 5px;
    
}
.modeliconleft:before{
    content: '';                 /*CSS伪类用法*/
        position: absolute;         /*定位背景横线的位置*/
        top: 50%;
        margin-top: -8px;
        left: -5px;
        width: 2px;
        height: 16px;
        background: #3F59AE;
        opacity: 1;
        border-radius: 2px;
   }
.modeliconright{
    display: flex;
    align-items: center;
    justify-content: flex-end;
}
.edit{
    margin-left: 30px;
    display: flex;
    align-items: center;

}
.editext{
    margin: 0 5px;
}
   .exposebtn{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 74px;
}
.formbtnleft{
    width: 120px;
    height: 40px;
    // background-color: #3F59AE;
    background-color: #FFFFFFFF;
    color: #3F59AE;
    font-size: 16px;
    line-height: 40px;
    text-align: center;
    // border: 0;
    border: 1px solid #3F59AE;
    font-family: PingFang SC;

    border-radius: 2px;
    margin: 0 28px;
    &:hover {
        background-color: #fff;
        color: #374C92;
        border: 1px solid #374C92;
        cursor:pointer;
    }

}
.formbtnright{
    width: 120px;
    height: 40px;
    background-color: #3F59AE;
    // background-color: #FFFFFFFF;
    color: #fff;
    font-size: 16px;
    line-height: 40px;
    text-align: center;
    font-family: PingFang SC;

    // border: 0;
    border: 1px solid #3F59AE;
    border-radius: 2px;
    margin: 0 28px;
    &:hover {
        background-color: #374C92;
        border: 1px solid #374C92;
        color: #fff;
        cursor:pointer;
    }
}
