.formbtn{
    width: 120px;
    height: 40px;
    // background-color: #3F59AE;
    background-color: #FFFFFFFF;
    color: #3F59AE;
    font-size: 16px;
    line-height: 40px;
    text-align: center;
    font-family: PingFang SC;

    // border: 0;
    border: 1px solid #3F59AE;
    border-radius: 2px;
    &:hover {
        background-color: #3F59AE;
        color: #fff;
    }
}

.projectform{
    margin-left: 10px;
}

.followbtn{
    margin-bottom: 20px;
}
.addbtn{
    width: 90px;
    height: 34px;
    background: #3FB97C;
    opacity: 1;
    border-radius: 2px;
    font-size: 16px;
    font-family: PingFang SC;
    font-weight: 400;
    line-height: 22px;
    color: #FFFFFF;
    opacity: 1;
    border: 0;
}
