.exposeContent{
    padding: 24px;
}
.exposeRoad{
    // width: 480px;
    // height: 400px;
    width: 100%;
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    box-shadow: 0px 2px 23px rgba(36, 46, 76, 0.2);
    padding: 15px;
    // margin: 100px;
    margin-right: 10px;
    position: relative;
    min-width: 250px;
}
.widthImg{
    // width: 450px;
    width: 100%;
    // height: 260px;
}
.exposeTitle{
    padding: 18px 0;
    text-align: center;
    font-size: 18px;
    font-family: PingFang SC;
    font-weight: bold;
    line-height: 27px;
    color: rgba(0, 0, 0, 0.85);
    min-height: 90px;
}
.Select{
    width: 90%;
}
.exposeactive{
    border: 1px solid #3F59AE;
    box-shadow: 0px 2px 23px rgba(63, 89, 174, 0.5);

    width: 100%;
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 15px;
    margin-right: 10px;
    position: relative;
    min-width: 250px;
}
.exposebtn{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 74px;
    padding-bottom: 50px;
}
.formbtnleft{
    width: 120px;
    height: 40px;
    // background-color: #3F59AE;
    background-color: #FFFFFFFF;
    color: #3F59AE;
    font-size: 16px;
    line-height: 40px;
    text-align: center;
    // border: 0;
    border: 1px solid #3F59AE;
    border-radius: 2px;
    margin: 0 28px;
    font-family: PingFang SC;

    &:hover {
        background-color: #fff;
        color: #374C92;
        border: 1px solid #374C92;
        cursor:pointer;
    }

}
.formbtnright{
    width: 120px;
    height: 40px;
    background-color: #3F59AE;
    // background-color: #FFFFFFFF;
    color: #fff;
    font-size: 16px;
    line-height: 40px;
    text-align: center;
    font-family: PingFang SC;

    // border: 0;
    border: 1px solid #3F59AE;
    border-radius: 2px;
    margin: 0 28px;
    &:hover {
        background-color: #374C92;
        border: 1px solid #374C92;
        color: #fff;
        cursor:pointer;
    }
}
.activeImg{
    width: 30px;
    position: absolute;
    left: 16px;
    top: 15px;
}
