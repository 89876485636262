.bread{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 12px 24px;
    border-bottom: 1px solid #E9E9E9;
    border-bottom: 1px solid #E9E9E9;
    background-color: #fff;
}
.breadImage{
    width: 25px;
    height: 25px;
    // background-color: aqua;
}
.breadText{
    font-size: 18px;
    font-weight: bold;
    line-height: 28px;
    color: rgba(0, 0, 0, 0.85);
    margin-left: 9px;
}
