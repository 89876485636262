.linecontent{
    width: 100%;
    padding: 20px 60px;
}
.lineImg{
    width: 100%;
    height: 0;
    padding-bottom: 59.4%;
    background-image: url('../../assets/images/lineImg/bg.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position: center;
    position: relative;
}
.oneClass{
    position: absolute;
    left: 6.3%;
    top: 14.3%;
    width:20.4%;
}
.twoClass{
    position: absolute;
    left: 12.3%;
    top: 24%;
    width: 14.4%;
}
.threeClass{
    position: absolute;
    left: 6.3%;
    top: 30.5%;
    width: 23.6%;
}
.fourClass{
    position: absolute;
    left: 12.3%;
    top: 61%;
    width: 14.4%;
}
.fiveClass{
    position: absolute;
    left: 6.3%;
    top: 63.57%;
    width: 23.9%;
}
.sixClass{
    position: absolute;
    left: 12.3%;
    top: 91.5%;
    width: 14.4%;
}

.sevenClass{
    position: absolute;
    left: 37.7%;
    top: 17.4%;
    width: 16.1%;
}

.eightClass{
    position: absolute;
    left: 32%;
    top: 21.2%;
    width: 21.9%;
}
.nineClass{
    position: absolute;
    left: 32%;
    top: 44%;
    width: 21.9%;
}
.tenClass{
    position: absolute;
    left: 35%;
    top: 44.5%;
    width: 24%;
}
.elevenClass{
    position: absolute;
    left: 37.8%;
    top: 60.2%;
    width: 12.8%;
}
.twelveClass{
    position: absolute;
    left: 33.6%;
    top: 63.5%;
    width: 17%;
}
.thirteenClass{
    position: absolute;
    left: 37.7%;
    top: 94.5%;
    width: 16.2%;
}

.fourteenClass{
    position: absolute;
    left: 64.8%;
    top: 17.5%;
    width: 17.9%;
}
.fifteenClass{
    position: absolute;
    left: 64.8%;
    top: 28.2%;
    width: 17.9%;
}
.sixteenClass{
    position: absolute;
    left: 64.8%;
    top: 44.2%;
    width: 17.9%;
}
.seventeenClass{
    position: absolute;
    left: 68.1%;
    top: 57.4%;
    width: 14.6%;
}
.eighteenClass{
    position: absolute;
    left: 68.1%;
    top: 61.1%;
    width: 21.5%;
}
.nineteenClass{
    position: absolute;
    left: 59.1%;
    top: 69.2%;
    width: 32.4%;
}
.twentyClass{
    position: absolute;
    left: 68.1%;
    top: 61.2%;
    width: 25.4%;
}
.twentyoneClass{
    position: absolute;
    left: 59.1%;
    top: 81.8%;
    width: 26.4%;
}
.twentytwoClass{
    position: absolute;
    left: 64.8%;
    top: 94.4%;
    width: 17.9%;
}
